import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="stamp"
export default class extends Controller {
  static targets = ["submitButton", "submitLoading", "successMessage", "failureMessage"];

  handleFailure() {
    this.submitLoadingTarget.style.display = "none";
    this.failureMessageTarget.style.display = "block";
    this.submitButtonTarget.style.display = "none";
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.submitButtonTarget.style.display = "none";
    this.submitLoadingTarget.style.display = "block";
    const form = event.target;
    const formData = new FormData(form);

    // Send a POST request using Fetch API
    const response = await fetch(form.action, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json"
      }
    });
    const data = await response.json();
    console.log(data);
    // Handle the response
    if (data.result === 200) {
      this.handleSuccess();
    } else {
      this.handleFailure();
    }
  }

  handleSuccess() {
    this.submitLoadingTarget.style.display = "none";
    this.successMessageTarget.style.display = "block";
    this.submitButtonTarget.style.display = "none";
  }
}
